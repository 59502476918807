import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, unref as _unref, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "text-[12.5px] font-inter font-medium text-gray-700"
};
const _hoisted_2 = {
  class: "w-full relative"
};
const _hoisted_3 = {
  key: 0,
  class: "absolute left-0 top-0 flex items-center justify-center w-9 h-9 bg-gray-200 rounded-l-md text-[13px] font-medium font-inter"
};
const _hoisted_4 = {
  key: 1,
  class: "mr-2 w-full"
};
const _hoisted_5 = {
  key: 2,
  class: "text-[11px] font-bold text-red-600 font-inter mt-1"
};
import { ref } from "vue";
import fieldHandler from "@/composables/fieldHandler";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
export default {
  __name: 'Display',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  emits: ['getField'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      capitalizeString
    } = fieldHandler();
    const field = ref(props.field ?? null);
    const error = ref(false);
    const errorMsg = ref();
    const trumConfig = {
      autogrowOnEnter: true,
      btns: [['undo', 'redo'],
      // Only supported in Blink browsers
      ['formatting'], ['strong', 'em'], ['link'], ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'], ['unorderedList', 'orderedList'], ['horizontalRule']]
    };

    // Capitalize string
    field.value.title = capitalizeString(props.field.title);
    const emitChanges = () => {
      error.value = false;
      errorMsg.value = '';

      // Format before emitting
      if (field.value.type === 'tal' && field.value.value) {
        const regex = /^[\d,]*\.?\d*$/;
        if (!regex.test(field.value.value)) {
          field.value.value = '';
          error.value = true;
          errorMsg.value = 'Indtast venligst kun tal';
          return false;
        }
      }
      emit('getField', field.value);
    };
    return (_ctx, _cache) => {
      const _component_Inputs_Fields_Currency = _resolveComponent("Inputs-Fields-Currency");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("span", _hoisted_1, _toDisplayString(field.value.title), 1), field.value.type === 'tal' || field.value.type === 'tekst' ? _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 0,
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => field.value.value = $event),
        class: _normalizeClass([error.value ? 'border-red-500' : 'border-gray-200', "block w-full pl-2 rounded shadow-sm mt-[2px] border border-gray-200 h-[32px] text-sm font-inter"]),
        onBlur: emitChanges
      }, null, 34)), [[_vModelText, field.value.value]]) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [field.value.type === 'valuta' ? (_openBlock(), _createElementBlock("div", _hoisted_3, "kr. ")) : _createCommentVNode("", true), field.value.type === 'valuta' ? (_openBlock(), _createBlock(_component_Inputs_Fields_Currency, {
        key: 1,
        modelValue: field.value.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => field.value.value = $event),
        onBlur: emitChanges,
        class: "border rounded focus:outline-none w-full h-9 text-[13px] pl-10"
      }, null, 8, ["modelValue"])) : _createCommentVNode("", true)]), field.value.type === 'beskrivelse' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_unref(Trumbowyg), {
        modelValue: field.value.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => field.value.value = $event),
        defaultValue: field.value.value,
        config: trumConfig,
        onBlur: emitChanges,
        class: "form-control",
        name: "content"
      }, null, 8, ["modelValue", "defaultValue"])])) : _createCommentVNode("", true), errorMsg.value ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(errorMsg.value), 1)) : _createCommentVNode("", true)]);
    };
  }
};