import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useCurrencyInput } from 'vue-currency-input';
export default {
  __name: 'Currency',
  props: {
    modelValue: null
  },
  setup(__props) {
    const props = __props;
    const {
      inputRef
    } = useCurrencyInput({
      currency: 'DKK',
      currencyDisplay: 'hidden',
      autoDecimalDigits: true
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("input", {
        ref_key: "inputRef",
        ref: inputRef,
        type: "text"
      }, null, 512);
    };
  }
};