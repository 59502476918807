import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { useRoute } from "vue-router/dist/vue-router";
import { Auth } from "@/store/auth";
import { Global } from "@/store/global";
export default {
  __name: 'App',
  setup(__props) {
    const auth = Auth();
    const route = useRoute();
    const global = Global();
    if (auth.check()) {
      global.setGlobalData();
    }
    return (_ctx, _cache) => {
      const _component_Layout_Top = _resolveComponent("Layout-Top");
      const _component_Layout_TopLogged = _resolveComponent("Layout-TopLogged");
      const _component_router_view = _resolveComponent("router-view");
      const _component_Layout_footer = _resolveComponent("Layout-footer");
      return _openBlock(), _createElementBlock(_Fragment, null, [!_unref(auth).check() && _unref(route).fullPath !== '/login' && _unref(route).fullPath !== '/register' ? (_openBlock(), _createBlock(_component_Layout_Top, {
        key: 0
      })) : _createCommentVNode("", true), _unref(auth).check() ? (_openBlock(), _createBlock(_component_Layout_TopLogged, {
        key: 1
      })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_router_view, {
        key: _unref(route).fullPath
      })), !_unref(auth).check() && _unref(route).fullPath !== '/login' && _unref(route).fullPath !== '/register' ? (_openBlock(), _createBlock(_component_Layout_footer, {
        key: 2
      })) : _createCommentVNode("", true)], 64);
    };
  }
};