import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "bg-gray-800 w-full rounded-b -mt-[2px] font-inter font-light tracking-wide text-[14px] text-white flex items-center justify-center relative shadow-md"
};
export default {
  __name: 'UnderTopLogged',
  props: {
    menu: {
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_RouterLink = _resolveComponent("RouterLink");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.menu, menu => {
        return _openBlock(), _createBlock(_component_RouterLink, {
          key: _ctx.$route.fullPath,
          "exact-active-class": "bg-gray-900 font-medium",
          to: menu.slug,
          class: "hover-transition hover:bg-gray-900 px-6 py-3"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(menu.title), 1)]),
          _: 2
        }, 1032, ["to"]);
      }), 128))]);
    };
  }
};