import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "text-xl font-inter font-medium"
};
export default {
  __name: 'BlockTitle',
  props: {
    title: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(__props.title), 1);
    };
  }
};