import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "relative"
};
const _hoisted_2 = {
  class: "p-10"
};
export function render(_ctx, _cache) {
  const _component_Layout_BlockTitle = _resolveComponent("Layout-BlockTitle");
  const _component_DataSearcher = _resolveComponent("DataSearcher");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Layout_BlockTitle, {
    title: "Udregning af data",
    class: "mb-2"
  }), _createVNode(_component_DataSearcher)])]);
}