import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'Block',
  props: {
    style: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(__props.style ? '' : 'bg-white rounded-md p-2 drop-shadow-[0_10px_10px_rgba(0,0,0,0.03)] text-sm font-inter')
      }, [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode(" Der opstod en fejl ")])], 2);
    };
  }
};