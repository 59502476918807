import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "relative"
};
const _hoisted_2 = {
  key: 2,
  class: "p-10"
};
const _hoisted_3 = {
  class: "grid gap-5 grid-cols-3 mt-3"
};
const _hoisted_4 = {
  class: "w-full border border-gray-300 hover:border-gray-200 rounded-md text-gray-700 hover-transition cursor-pointer hover:bg-gray-300 hover:border-gray-400 h-[300px]"
};
const _hoisted_5 = {
  class: "w-20 h-20 rounded-full bg-gray-500 flex items-center justify-center text-white text-2xl"
};
const _hoisted_6 = {
  class: "text-lg mt-5 font-medium"
};
const _hoisted_7 = {
  key: 0,
  class: "text-primary-Darker1 font-semibold text-xs"
};
const _hoisted_8 = {
  key: 1,
  class: "text-xs invisible cursor-none"
};
import { onMounted, ref } from "vue";
import axios from "axios";
export default {
  __name: 'Fields',
  setup(__props) {
    const fields = ref();
    const loading = ref(true);
    const error = ref();
    onMounted(() => {
      try {
        axios.get('/fields', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then(response => response.data).then(response => {
          fields.value = response.fields;
          loading.value = false;
        }).catch(response => {
          error.value = response;
        });
      } catch (e) {
        error.value = e;
      }
    });
    return (_ctx, _cache) => {
      const _component_Api_Server_Loading = _resolveComponent("Api-Server-Loading");
      const _component_Api_Server_Error = _resolveComponent("Api-Server-Error");
      const _component_Layout_BlockTitle = _resolveComponent("Layout-BlockTitle");
      const _component_RouterLink = _resolveComponent("RouterLink");
      const _component_CreateNewBox = _resolveComponent("CreateNewBox");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [loading.value && !error.value ? (_openBlock(), _createBlock(_component_Api_Server_Loading, {
        key: 0
      })) : _createCommentVNode("", true), error.value ? (_openBlock(), _createBlock(_component_Api_Server_Error, {
        key: 1,
        error: error.value
      }, null, 8, ["error"])) : _createCommentVNode("", true), !loading.value && !error.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_Layout_BlockTitle, {
        title: "Felter skabeloner"
      }), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields.value, field => {
        return _openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_RouterLink, {
          to: '/edit/field/' + field.id,
          class: "h-full flex flex-col justify-center items-center"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(field.title.substring(0, 1)), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(field.title), 1), field.is_primary ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Primære skabelon")) : (_openBlock(), _createElementBlock("div", _hoisted_8, "."))]),
          _: 2
        }, 1032, ["to"])]);
      }), 256)), _createVNode(_component_RouterLink, {
        to: "/new/field"
      }, {
        default: _withCtx(() => [_createVNode(_component_CreateNewBox, {
          css: "h-[300px]",
          text: "Opret ny felt",
          showInvisible: true
        })]),
        _: 1
      })])])) : _createCommentVNode("", true)]);
    };
  }
};