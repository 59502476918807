import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "auth-content"
};
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_Layout_Block = _resolveComponent("Layout-Block");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Layout_Block, {
    class: "mt-5"
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  })]);
}