import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "auth-content"
};
export default {
  __name: 'Layout',
  setup(__props) {
    const menu = [{
      title: 'Kartotek',
      slug: '/directory'
    }, {
      title: 'Felter',
      slug: '/directory/fields'
    }];
    return (_ctx, _cache) => {
      const _component_Layout_UnderTopLogged = _resolveComponent("Layout-UnderTopLogged");
      const _component_router_view = _resolveComponent("router-view");
      const _component_Layout_Block = _resolveComponent("Layout-Block");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [menu ? (_openBlock(), _createBlock(_component_Layout_UnderTopLogged, {
        key: 0,
        menu: menu
      })) : _createCommentVNode("", true), _createVNode(_component_Layout_Block, {
        class: "mt-5"
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      })]);
    };
  }
};