import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 0,
  class: "max-h-[190px] overflow-y-scroll"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "line-clamp-1"
};
const _hoisted_6 = {
  key: 1
};
import colorHandler from "@/composables/colorHandler";
export default {
  __name: 'DataDirectoryTasks',
  props: {
    tasks: {
      type: Array,
      required: true
    },
    title: {
      type: Object,
      default: {
        oneTask: 'opgave',
        manyTask: 'opgaver'
      }
    }
  },
  setup(__props) {
    const props = __props;
    const {
      colors
    } = colorHandler();
    const titleClass = 'text-[15px] font-inter font-medium bg-gray-200 -m-1 mb-0 p-3 rounded-t-md';
    const boxClass = 'w-full h-auto px-2 py-[5px] rounded-md shadow text-white mt-[8px] shadow text-xs font-medium font-inter hover:opacity-80 cursor-pointer overflow-hidden';
    const getTaskColor = task_color => {
      return colors.filter(color => color.name === task_color)[0].css;
    };
    return (_ctx, _cache) => {
      const _component_Empty = _resolveComponent("Empty");
      const _component_Layout_Block = _resolveComponent("Layout-Block");
      return _openBlock(), _createBlock(_component_Layout_Block, null, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(titleClass)
        }, [__props.tasks.length === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.tasks.length) + " " + _toDisplayString(__props.title.oneTask), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.tasks.length) + " " + _toDisplayString(__props.title.manyTask), 1))]), __props.tasks.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tasks, (task, index) => {
          return _openBlock(), _createElementBlock("div", {
            onClick: $event => _ctx.$emit('selectedTask', task),
            class: _normalizeClass([getTaskColor(task.task_color), boxClass])
          }, [_createElementVNode("p", _hoisted_5, "#" + _toDisplayString(task.task_number) + " " + _toDisplayString(task.title), 1)], 10, _hoisted_4);
        }), 256))])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_Empty, {
          hideButton: true,
          text: "Ingen opgaver fundet"
        })]))]),
        _: 1
      });
    };
  }
};