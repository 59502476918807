import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { Auth } from "@/store/auth";
export default {
  __name: 'Logout',
  setup(__props) {
    const auth = Auth();
    auth.logout();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, " Logget ud ");
    };
  }
};